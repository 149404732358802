#container {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid black;
  box-shadow: 3px 3px 3px #888;
  border-radius: 5px;
  padding: 0;
  margin: 10px;
  min-height: 66vh;
  max-height: 66vh;
}

.inner-table1 {
    table-layout: fixed;
    width: 100%; 
}

#locationName,
#address,
#city,
#state,
#postalCode,
#phone,
#fax {
    text-align: left;
    font-size: smaller;
    padding: initial;
}

#table-content {
  padding: 0;
  overflow-y: auto; 
  overflow-x: hidden; 
  flex-grow: 1; 
  min-height: 59vh;
  max-height: 59vh;
}

.footer-buttons {
  padding: 3px; 
  background-color: white;  
  border-top: 1px solid #ccc; 
  z-index: 10; 
  position: absolute;
  bottom: 0; 
  left: 0; 
  right: 0;
  border-radius: 5px;
}

.btn-footer {
  background-color: #033671 !important;
  color: white !important;
  margin-right: 1%;
  cursor: pointer;
  width: auto !important;
  height: fit-content;
}

#div-footer {
  display: flex;
  flex-direction: row-reverse;
  border-radius: 5px;
}

.disabled-btn-footer {
  background-color: rgba(154, 169, 188, 1) !important;
  color: white !important;
  margin-right: 1%;
  cursor: not-allowed;
  width: auto !important;
  height: fit-content;
}

.btn-footer:hover {
  color: rgb(204, 171, 94) !important;
}

.table > thead > tr > th {
  background-color: #5E7492 !important;
}

#icon {
  padding-left: 110px;
}

.addIcon {
  font-size: 15px;
  cursor: pointer;
  float: left;
}

.addIcon:hover {
  color: rgb(204, 171, 94) !important;
}

.top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 6px;
  font-size: medium;
  background-color: #042454;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 6px;
  color: #fff;
}

.top-container > button {
  background-color: #042454;
  color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  font-size: 15px;
}

.top-container > button:hover {
  background-color: #042454;
  color: #FEC336;
  border: 1px solid #000;
}

.top-title-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inner-table1 {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2px !important;
  max-height: 400px;
  overflow-y: auto;
}

.inner-table1 td {
  text-align: left;
}

.outer-table {
  vertical-align: middle !important;
  flex-grow: 1;
  background-color: white;
  border-radius: 4px;
  overflow-y: auto;
}

.inner-table1 .outer-table {
  border-collapse: collapse;
  border: 2px solid rgb(140, 140, 140);
  font-family: sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.practice-item, .location-item {
  cursor: pointer;
}

.inner-table1 tr:nth-child(odd) td {
  background-color: rgb(243, 243, 243);
  color: black;
}

.inner-table1 tr:nth-child(even) td {
  background-color: rgb(249, 249, 249);
  color: black;
}

.inner-table1 tr.clicked-row td {
  background-color: rgb(143, 143, 143) !important;
}

.inner-table1 tr:hover td {
  background-color: #ccc;
}

.outer-table tr:hover td {
  background-color: #ccc;
}

.outer-table tr.clicked-outer-row td {
  background-color: gray !important;
}

.outer-table td:nth-child(odd) {
  width: 20%;
}