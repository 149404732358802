.managers-wappper {
    display: flex; 
    margin-bottom: 10px;
}

.managers-label {
    font-size: 12px !important;
    color: #222222;
    width: 28%;
}

.search-icon-wrappe{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-icon-wrapper:hover{
    cursor: pointer;
}
.delete-icon {
    cursor: pointer;
}

.managers-form-input{
    width: 200px;
    height: 25px;
    padding: 2px;
    margin: 5px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
}

.search-modal{
    width: 30% !important;
    padding: 12px;
    margin: auto;
}

.remove-smg-modal{
    width: 20% !important;
    padding: 12px;
    margin: auto;
}
.profile-tabs-wrapper >button .active{
    background-color: #F5F5E4;
    color: #222222;
}

.manager-label-input{
    display: flex;
    justify-content: space-evenly;
    width: 88%;
}