.table1 {
  font-family: Verdana, Arial, sans-serif;
  font-size: smaller;
  font-weight: normal !important;
}

.table1 tr th {
  padding: 5px;
  background-color: rgb(30, 50, 100) !important;
}

.table1 > tbody{
   font-size: 11px !important;
}


th,
td {
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
}

th[scope='col'] {
  background-color: #505050;
  color: #fff;
}

th[scope='row'] {
  background-color: #d6ecd4;
}

.table1 tr.selected td {
  background-color: yellow;
}

caption {
  caption-side: bottom;
  padding: 10px;
}

.edu-content {
  display: flex;
  justify-content: space-between;
}

.edu-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.edu-control {
  display: grid;
  padding: 5px;
  height: 70px;
}

.edu-btn-right {
  margin-top: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .edu-btn {
    background: blue;
    margin-right: 10px;
    background-color: #0a0a23;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 2px rgb(0, 0, 0);

    &:hover {
      background-color: green;
      color: #fff;
      transition: 0.7s;
      font-weight: bold;
    }
  }

  .edu-btn-can {
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 2px rgb(0, 0, 0);

    &:hover {
      background-color: green;
      color: #fff;
      font-weight: bold;
      transition: 0.7s;
    }
  }
}

.edu-btn-close {
  background: blue;
  margin-right: 10px;
  max-height: 150px;
  background-color: #0a0a23;
  color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0);

  &:hover {
    background-color: green;
    color: #fff;
    transition: 0.7s;
    font-weight: bold;
  }
}

.edu-view-btn-panel {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  justify-items: center;

  .edu-view-btn {
    background-color: rgba(154, 169, 188, 1) !important;
    color: white !important;
    margin-right: "20px";
    cursor: pointer;
    width: auto !important;
    height: fit-content;

    &:hover {
      color: rgb(204, 171, 94) !important;
    }
  }
}

.edu-pop-up {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
