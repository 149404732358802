.primary-button {
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #0a3370 !important;
}
.primary-button:hover {
    color: #FEC336 !important;
}

.button-wrapper {
    display: flex;
    justify-content: flex-end;
    size: 50px;
}