@import url('https://fonts.googleapis.com/css2?family=Alef&display=swap');

#middle-tab-text {
    font-family: 'Alef', sans-serif !important;
    font-weight: normal;
    font-size: 23px;
    }

#practise-form {
    padding: 0px;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid black;
    box-shadow: 3px 3px 3px #888;
    background: url(../../../public/images/search.png) no-repeat rgb(250, 250, 250);
    background-position: 98% center;
}

#txt-practice,
#txt-location,
#txt-city,
#txt-zip,
#txt-dropdown {
    height: 32px !important;
    margin-left: 0px !important;
    font-size: 13px !important;
    padding-left: 5px;
}

#form-label {
    display: flex;
}

#txt-practice #txt-location{
    width: 70%;
    height: max-content;
    margin-left: 5px !important;
}

#txt-dropdown {
    margin-left: 5px;
    text-transform: uppercase;
}

#search-btn {
    writing-mode: vertical-lr;
    transform: scale(-1,-1);
    padding: 0;
    margin-right: 0px;
}

#srch-btn {
    border-radius: 5px;
}

.btn {
    transition: none;
    padding: revert !important;
    background-color: #5E7492 !important;
    color: white !important;
    cursor: pointer;
    border-radius: 5px;
    height: 100%;
    border: 1px solid black !important;
}

.btn label {
    padding: 2px;
}

.btn:hover {
    color: rgb(238, 175, 26) !important;
}

#search-row {
    padding-top: 15px;
    padding-bottom: 10px;
}

.col-4 {
    display: flex;
    width: 30% !important;
}

.col-6 {
    display: flex;
    width: 45% !important;
}

.col-11 {

    align-items: end;
    padding: 10px 20px 0px 0px;
}
.menu-label {
    display: flex;
    text-wrap: nowrap;
    margin: 0px 11px;
    font-size: 12px !important;
    font-weight: 500;
}

.state-value{
    text-transform: uppercase;
}