.about-modal-header {
    padding: 2px;
    height: auto;
    width: auto;
}

.about-titlebar {
    border: 1px solid #011127;
    background: #042454 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkEAIAAACY3hF0AAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAjklEQVQoz2MozWgXXjiFiYGBgYGBYZTCQf13+h/NUM/0p++P/N8fTD9Df+n9LmX6cfPn419FTN+lf9j++sisNE+fzfERs9xZ7Uu2s5i+Lvj2+ec7pm8Lvn/6+Y6Zb7asgPEBZq7Tknf0K5jZv4p91t3E9GPaz87fn5l+hv7S+1PK9I/zX9a/34PDt0OEAgAMPTxqCmPvSwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNi0wNVQxMDo0Nzo1Ni0wNzowMAswTf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDYtMDVUMTA6NDc6NTYtMDc6MDB6bfVBAAAAAElFTkSuQmCC) 50% 45% repeat-x;
    color: #fff;
    font-weight: bold;
    font-family: Verdana, Arial, sans-serif;
    width: auto;
    height: 35px;
    padding-left: 10px;
}

.about-content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 8px;
    padding: 7px;

}
.about-inner-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
}
.about-inner-text {
    position: relative;
    padding: 3px 6px;
    font-size: 15px;
    font-family: Verdana, Arial, sans-serif;
    font-weight: normal;
    text-align: right;

}

.bottom-text {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}

.button-wrapper {
    display: flex;
    justify-content: flex-end;
}

.button {
    background: #042454 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkEAIAAACY3hF0AAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAjklEQVQoz2MozWgXXjiFiYGBgYGBYZTCQf13+h/NUM/0p++P/N8fTD9Df+n9LmX6cfPn419FTN+lf9j++sisNE+fzfERs9xZ7Uu2s5i+Lvj2+ec7pm8Lvn/6+Y6Zb7asgPEBZq7Tknf0K5jZv4p91t3E9GPaz87fn5l+hv7S+1PK9I/zX9a/34PDt0OEAgAMPTxqCmPvSwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNi0wNVQxMDo0Nzo1Ni0wNzowMAswTf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDYtMDVUMTA6NDc6NTYtMDc6MDB6bfVBAAAAAElFTkSuQmCC) 50% 45% repeat-x;
    border-radius: 2px;
    width: 50px;
    color: black;
}

#title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}