.table-custom-style {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2px !important;
    overflow: hidden;

    thead {
        tr {
            th {
                background-color: var(--table-headers-background); // Use CSS variables or hardcoded values
                color: white;
                padding: 7px; // Add any padding or styling you want
                text-align: left; // Align header text to the left
                border-bottom: 1px solid #ddd; // Bottom border for header cells
                font-size: 12px;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: #ffffff; // Directly use white color for even rows
                color: #000000; // Text color for odd rows
            }

            &:nth-child(odd) {
                background-color: #f9f9f9; // Directly use light gray color for odd rows
                color: #000000; // Text color for odd rows
            }

            &:hover td {
                background-color: #ccc; // Hover effect for clickable rows
            }

            td {
                padding: 3px !important; // Padding for table cells
                text-align: left; // Align cell text to the left
            }
        }

        .text-center {
            text-align: center; // Center-align text for cells with this class
        }
    }
}