/* addPracticeModalStyles.scss */

.practice-container {
    padding: 0px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.practice-header {
    background: linear-gradient(180deg, rgba(0, 36, 83, 1) 0%, rgba(14, 48, 94, 1) 47%, rgba(154, 169, 188, 1) 100%) repeat-x #c0cad7 0 0 / cover;
    color: #fff;
    font-weight: bold;
    font-family: Verdana, Arial, sans-serif;
    font-size: 1.2em;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: left;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.input {
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    background-color: rgb(245, 245, 228) !important;
    border-radius: 4px;
    width: 100%;
}

.dropdown {
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    background: -webkit-linear-gradient(top, rgba(242, 246, 248, 1) 0%, rgba(225, 228, 230, 1) 50%, rgba(194, 200, 204, 1) 51%, rgba(239, 247, 252, 1) 100%);
    border-radius: 4px;
    width: 100%;
}

.custom-border {
    border: 1px solid black;
    padding: 4px;
    border-radius: 5px;
    margin-top: 20px;
}

.header-center {
    text-align: center;
    margin-bottom: -26px;
    font-size: 20px;
}

.sc-NxrB {
    padding: 0 !important;
}

.text-center {
    text-align: center;
}

.save-button {
    display: block;
    margin: -10px auto;
    padding: 4px 12px;
    background: linear-gradient(180deg, rgba(0, 36, 83, 1) 0%, rgba(14, 48, 94, 1) 47%, rgba(154, 169, 188, 1) 100%) repeat-x #c0cad7 0 0 / cover;
    color: #fff !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    left: 16rem;
}

.save-button:hover {
    background-color: rgb(245, 245, 228) !important;
}

.sc-dCFGXG{
    color: white !important;
}
.practice-button-container{
position: relative;
    margin-top: 0px;
    margin-bottom: 14px;
}

.table-container-practice {
    max-height: 200px; 
    overflow-y: auto;
}

.top-container > button:hover {
    background-color: #042454;
    color: #FEC336;
    border: 1px solid #000;
  }

.location-editor-label{
    color:#fff !important;
}