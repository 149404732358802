.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      display: none;
    }

    label {
      font-family: Verdana, Arial, sans-serif;
      font-size: 10px !important;
      color: #222222;
      padding: 0px 20px;
      border: 1px solid rgb(18, 56, 92);
      border-radius: 5px;
      background-color: #E8F0FE;
      transition: background-color 0.15s, color 0.15s, border-color 0.15s;

      &:hover {
        border-color: #213140;
      }

      &.active {
        background-color: rgb(18, 56, 92);
        color: #fff;
        border-color: rgb(18, 56, 92);
      }

      &:first-of-type {
        border-radius: 5px 0 0 5px; // Left button
        border-right: none; // Remove right border
      }

      &:last-of-type {
        border-radius: 0 5px 5px 0; // Right button
        border-left: none; // Remove left border
      }
    }
  }
