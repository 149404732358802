.staff-specialities-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    background: #042454 url('../../../../../public/images/ui-bg_flat_90_042454_40x100.png') 50% 50% repeat-x;
    font-weight: normal !important;
    border-radius: 5px;
    font-size: 15px;
}

.staff-specialities-button.open {
    color: #fec336 !important;
}

.staff-specialities-arrow {
    color: white;
    transition: color 0.3s;
}

.staff-specialities-arrow.down {
    color: #fec336 !important;
}

.specialities-popup {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.specialities-table {
    font-family: Verdana, Arial, sans-serif;
    margin: 5px !important;
    font-size: smaller;
    font-weight: normal !important;
}

.specialities-table th {
    background: #fcfdfd url('../../../../../public/images/ui-bg_flat_90_042454_40x100.png') 50% top repeat-x;
    background-color: rgb(10, 10, 10);
    color: white;
    text-align: left;
    width: 8px;
    margin: 0 !important;
    padding: 4px 2px 0 6px;
    font-size: smaller !important;
}

.specialities-table tr td{
    border-bottom: 1px dotted #666;
    width: 8px;
    font-size: smaller !important;
}

.specialities-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}