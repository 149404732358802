.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
}

header {
  height: 10%;
}

main {
  flex-grow: 1; 
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

footer {
  height: 10%;
}