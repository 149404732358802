.edit-practice-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.edit-practice-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 55%;
    margin-bottom: 5px;
}

.edit-practice-dropdown{
    padding: 5px;
    border: 1px solid #ccc;
    background: -webkit-linear-gradient(top, rgb(242, 246, 248) 0%, rgb(225, 228, 230) 50%, rgb(194, 200, 204) 51%, rgb(239, 247, 252) 100%);
    border-radius: 4px;
    width: 230px;
    font-size: 13px;
}

.edit-practice-input{
    width: 230px;
    height: 21px;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: 12px;
    padding-left: 5px;
}

.edit-practice-label{
    font-size: 13px !important;
    color: #000 !important;
    text-align: start !important;
}

.edit-practice-button{
    display: flex;
    justify-content: end;
}

