.location-dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.location-type-dropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.form-select{
    width: 90%;
    height: 33px;
    border-radius: 8px;
    border: 1px solid #0c0d0f;
    font-size: .85em;
}

.location-input {
    width: 94%;
    height: 25px;
    border-radius: 8px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: 13px;
    padding-left: 5px;
}
.location-input-medium {
    width: 40%;
    height: 25px;
    padding: 2px;
    font-size: 13px;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    padding-left: 5px;
}
.location-input-small {
    width: 12%;
    height: 25px;
    padding: 2px;
    margin: 5px;
    font-size: .85em;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
}

.time-input-small{
    width: 12%;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    font-size: .65em;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
}

.horizontal-line{
    color: #fff;
    background-color: #fff;
    border-top: 1px solid #000;
    width: 100%;
    margin-top: 15px;
}

.business-horizontal-line{
    color: #fff;
    background-color: #fff;
    border-top: 1px solid #000;
    width: 100%;
    opacity: .25;
}

.inner-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    padding: 5px;

}
.vertical-line {
    border-top: 1px solid #ddd;
    color: #fff;
    background-color: #fff;
    border-left: 1px solid #000;
    height: 470px;
    margin-right: 20px;
    opacity: .25;
}
.names-of-days{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    font-size: .85em;
    background-color: rgb(202, 214, 202);
}

.days-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 245, 226) !important;
    width: 100%;
    margin: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: .85em;
}

.text-area-box{
    display: flex;
    justify-content: space-evenly;
    background-color: rgb(255, 245, 226) !important;
    width: 100%;
    margin: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: .85em;
}

.external-comments{
    width: 48% !important;
}

.date-picker-box{
    border: 1px solid #F5F5E4;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.50);
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: .85em;
}

.toggle-wrapper{
    border: 1px solid #F5F5E4;
    padding: 5px 5px 5px 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.50);
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: .85em;
}

.toggle-wrapper > hr {
    color: #fff;
    background-color: #fff;
    border-top: 1px solid #000;
    width: 100%;
    margin: 6px 0px;
    opacity: 0.25;
}

.custom-date-picker {
    font-size: 30px;
    background-color: #F5F5E4;
}

.dob-field .form-input{
    background-color: #F5F5E4;
    height: 33px;
}

.dob-field .react-calendar__month-view__weekdays__weekday {
    font-size: 10px !important;
}

.phone-fax-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.location-phone-input{
    width: 90%;
    height: 25px;
    border-radius: 8px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: .85em;
    padding-left: 5px;
}

.location-label{
    font-size: 14px !important;
}

.form-control-custom{
    height: fit-content;
    font-size: 12px !important;
}

.confirm-weekdays-time-modal{
    width: 20% !important;
    padding: 12px;
    margin: auto;
}