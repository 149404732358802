.main-search-location-wrapper{
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid black;
    box-shadow: 3px 3px 3px #888;
    border-radius: 5px;
    padding: 15px;
    gap:20px;
    background-position: 99% center;
}
.input-search-location{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.search-location-input{
    display: flex;
    gap:10px;
}

.search-location{
    display: flex;
    gap:31px
}

.form-input-search-location{
    width: 221px;
    height: 22px;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: .85em;
    padding-left: 8px;
}
.search-label-input{
    display: flex;
    gap:15px;
}

.modal-search-location{
    width: 75%;
    height: fit-content;
}
.form-input-state-input{
    width:51px;
    height:22px;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: .85em;
    padding-left: 8px;
}

.form-input-zip-input{
    width: 91px;
    height:22px;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: .85em;
    padding-left: 8px;
}

.location-modal-title{
    margin-bottom: 10px;
}

.ok-button{
    display: flex;
    justify-content: end;
}

.location-button-wrapper {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    transform:scale(-1,-1);
    writing-mode: vertical-lr;
    margin-right: 0px;
}
.location-search-button{
    cursor: pointer;
    width: 31px;
    height: 80px;
    border-radius: 5px;
    background-color: #5e7492 !important;
    color: white !important;
    padding: 0px;
    border-color: antiquewhite;
    border: 1px solid black;
}

.location-button-text{
    color: white !important;
}

.location-button-text:hover{
    color: rgb(238, 175, 26) !important;
    cursor: pointer;
}

.assign-location{
    width: 300px;
}
.search-location-table-container{
    overflow: auto;
    max-height: 300px;
}
.search-location-table {
    max-height: 150px !important;
    overflow: auto;
}
.search-location-table tr th{
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
}
.search-location-table tr:nth-child(odd) td
{
  background-color: rgb(243, 243, 243);
  color: black;
}
.search-location-table tr:nth-child(even) td
{
  background-color: rgb(249, 249, 249);
  color: black;
}
.search-location-table tr.selected td {
    background-color: rgb(185, 184, 184) !important;
}
.search-location-table tr:hover td {
    background-color: #ccc;
}