.header-container {
    display: flex;
    justify-content: space-between;  
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo span {
    margin-right: 15px;
}

.sentara-logo, .mdoffice-logo {
    height: 50px;
    width: auto;
}

.rt-header-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.title {
    cursor: default;
    color: rgb(204, 171, 94);
    font-size: 26px;
    font-family: Alef, sans-serif;
    position: relative;
    z-index: 1; 
    margin-top: 12%;
}

img {
    max-width: 100%;
    height: auto;
}

.custom-select-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    vertical-align: middle;
    text-align: center;
    overflow: visible;
    border-radius: 5px;
    box-shadow: 0px 1px 15px #ffffff;
    z-index: 2;
}

.custom-select-dropdown .select-button {
    background-color: #042454;
    color: white;
    border: none;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    outline: none;
    
    &:hover {
        color: rgb(204, 171, 94);
    }

    &:focus {
        outline: none;
    }
}

.custom-select-dropdown .select-option-list {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
    padding: 0;
    list-style-type: none;
    min-width: 140px;
    font-size: 12px;
    text-align: left;
}

.custom-select-dropdown .select-option-list .li-title {
    background-color: #042454;
    color: rgb(204, 171, 94);
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;  
    font-weight: bold;
    cursor: default;
}

.custom-select-dropdown .select-option-list .menu-item {
    padding: 10px 15px;
    color: #333;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
}

.custom-select-dropdown .select-option-list {
    transition: all 0.2s ease-in-out;
}

.custom-select-dropdown .menu-item {
    transition: background-color 0.2s ease;
}