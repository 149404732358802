.edit-sqcn-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 5px;
}

.edit-date-picker{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 5px;
}

.edit-checkbox-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 80%;
    margin-bottom: 5px;
    gap: 22px;
}

.edit-sqcn-dropdown{
    padding: 5px;
    border: 1px solid #ccc;
    background: -webkit-linear-gradient(top, rgb(242, 246, 248) 0%, rgb(225, 228, 230) 50%, rgb(194, 200, 204) 51%, rgb(239, 247, 252) 100%);
    border-radius: 4px;
    width: 230px;
    font-size: 13px;
}

.edit-sqcn-input{
    width: 230px;
    height: 21px;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
    font-size: 12px;
}

.edit-sqcn-label{
    font-size: 13px !important;
    color: #000 !important;
    text-align: right !important;
    width: 230px;
}

.form-check-input {
    margin-bottom: 0 !important;
    padding: 0 !important;
    min-height: 20px !important;
    min-width: 20px !important;
}

.dob-field .form-input{
    height: 25px;
    font-size: 13px !important;
    width: 230px;
}

.calendar-icon{
    position: absolute;
    top: 0;
    cursor: pointer;
}