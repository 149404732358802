#main-container {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

#main-content {
    display: flex;
    border: 1px solid black;
    height: 100%;
    width: 100%;
}

#left-tab, #right-tab-staff {
    width: 9vh;
    min-width: 9vh;
    max-width: 9vh;
    border-right: 1px solid black;
    background: linear-gradient(180deg, rgba(0, 36, 83, 1) 0%, rgba(14, 48, 94, 1) 47%, rgba(154, 169, 188, 1) 100%) repeat-x #c0cad7 0 0 / cover;
}

#right-tab {
    width: 9vh;
    min-width: 9vh;
    max-width: 9vh;
    border-left: 1px solid black;
    background-color: white !important;
}

#left-tab-staff {
    width: 9vh;
    min-width: 9vh;
    max-width: 9vh;    
    border-right: 1px solid black;
    background-color: white !important;
}

#left-tab-text,
#right-tab-text-staff {
    display: block;
    margin-top: 2vh;
    text-align: center;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    border: none !important;
    background: none !important;
    cursor: pointer;
    font-size: 3.5vh;
    color: rgb(204, 171, 94);
    font-family: 'Alef', sans-serif;
}

#middle-tab {
    width: calc(100% - 18vh);
    height: 100%;
    background-color: white !important;
}

#right-tab-text,
#left-tab-text-staff {
    display: block;
    margin-top: 2vh;
    text-align: center;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    border: none !important;
    background: none !important;
    cursor: pointer;
    font-size: 3.5vh;
    color: #96b2d1 !important;
    font-family: 'Alef', sans-serif;
}

#right-tab-text-selected {
    display: block; 
    margin-top: 2vh;
    text-align: end;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    border: none !important;
    background: none !important;
    cursor: pointer;
    font-size: 2.5vh;
    color: #96b2d1 !important;
    font-family: 'Alef', sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 85vh;
}

#right-tab-text-staff-selected {
    display: block;
    margin-top: 2vh;
    text-align: end;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    border: none !important;
    background: none !important;
    cursor: pointer;
    font-size: 2.5vh;
    color: rgb(204, 171, 94);
    font-family: 'Alef', sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 85vh;
}