.btn-popup-footer {
    background: linear-gradient(180deg, rgba(0, 36, 83, 1) 0%, rgba(14, 48, 94, 1) 47%, rgba(154, 169, 188, 1) 100%) repeat-x #c0cad7 0 0 / cover;
    color: white !important;
    margin-right: 1%;
    cursor: pointer;
    width: auto !important;
}

.btn-popup-footer:hover {
    color: rgb(204, 171, 94) !important;
}

.button-popup-container {
    text-align: right;
}

.divider {
    border: none;
    border-top: 1px solid #000000;
}

.popup-header {
    background: #042454 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkEAIAAACY3hF0AAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAjklEQVQoz2MozWgXXjiFiYGBgYGBYZTCQf13+h/NUM/0p++P/N8fTD9Df+n9LmX6cfPn419FTN+lf9j++sisNE+fzfERs9xZ7Uu2s5i+Lvj2+ec7pm8Lvn/6+Y6Zb7asgPEBZq7Tknf0K5jZv4p91t3E9GPaz87fn5l+hv7S+1PK9I/zX9a/34PDt0OEAgAMPTxqCmPvSwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNi0wNVQxMDo0Nzo1Ni0wNzowMAswTf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDYtMDVUMTA6NDc6NTYtMDc6MDB6bfVBAAAAAElFTkSuQmCC) 50% 50% repeat-x;
    color:white;
    padding: 6px !important;
    border-radius: 0px;
    font-size: medium !important;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#close-btn {
    color: white !important;
    background: #042454 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkEAIAAACY3hF0AAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAjklEQVQoz2MozWgXXjiFiYGBgYGBYZTCQf13+h/NUM/0p++P/N8fTD9Df+n9LmX6cfPn419FTN+lf9j++sisNE+fzfERs9xZ7Uu2s5i+Lvj2+ec7pm8Lvn/6+Y6Zb7asgPEBZq7Tknf0K5jZv4p91t3E9GPaz87fn5l+hv7S+1PK9I/zX9a/34PDt0OEAgAMPTxqCmPvSwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNi0wNVQxMDo0Nzo1Ni0wNzowMAswTf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDYtMDVUMTA6NDc6NTYtMDc6MDB6bfVBAAAAAElFTkSuQmCC) 50% 50% repeat-x;
    border: 1px black;
    font-size: 15px;
}

#close-btn:hover {
    color: #f5b00c !important;
}