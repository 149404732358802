.staff-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.staff-title {
    font-family: 'Alef', sans-serif;
    font-weight: normal;
    font-size: 23px;
}

.error-message {
    position: absolute;
    top: 65%;
    left: 0;
    text-wrap: nowrap;
    font-size:10px;
    color: red;
    margin-top: 2px;
}
.staff-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid black;
    box-shadow: 3px 3px 3px #888;
    border-radius: 5px;
    background: url(../../../public/images/search.png) no-repeat rgb(250, 250, 250);
    background-position: 98.5% center;
}

.input-wrapper {
    display: flex;
}

.menu-search-label {
    text-wrap: nowrap;
    margin-right: 10px;
    font-size: 12px !important;
    font-weight: 500;
}

.form-input {
    display: flex;
    background-color: rgb(245, 245, 228) !important;
    width: 100%;
    color: #696363;
    border: 1px solid #696363;
    box-shadow: none;
    height: 20px;
    padding-left: 8px;
}

.form-staff-input {
    background-color: rgb(245, 245, 228) !important;
    color: #696363;
    border: 1px solid #696363;
    box-shadow: none;
    height: 20px;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 13px;
}

.input-row {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.error-input {
    border: 1px solid red !important;
    box-shadow: red !important;
}

.staff-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 50px;
    padding-top: 10px;
    padding-bottom: 16px;
}

.ssn-label {
    margin-left: 80px;
    margin-bottom: 5px;
}

.first-last-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.staff-container .staff-form .button-wrapper {
    height: auto;
}

.search-button {
    cursor: pointer;
    width: 31px;
    border-radius: 5px;
    background-color: #5e7492 !important;
    color: white !important;
    padding: 0px;
    border-color: antiquewhite;
    border: 1px solid black;
}

.btn-text {
    color: white !important;
}

.btn-text:hover {
    color: rgb(238, 175, 26) !important;
    cursor: pointer;
}

.staff-container .staff-form .search-button label {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding: 0;
    margin: -5px;
}

.table-container {
    min-height: 59vh;
    max-height: 59vh;
    overflow: auto;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 5px;
    border: 1px solid black;
    box-shadow: 3px 3px 3px #888;
    border-radius: 5px;
    font-size: 0.70em;
    text-align: center;
    background-color: rgb(250, 250, 250);

    tr>td {
        padding: 0 !important;
        text-align: -webkit-center;
    }
}

.button-wrapper {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
}

.button-wrapper .list-button {
    padding: 5px 18px;
    font-size: 14px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: rgb(30, 50, 100) !important;
    font-weight: normal;
    opacity: 0.35;
    filter: Alpha(Opacity=35);
    background-image: none;
    color: white;
    height: 34px;

    &:enabled {
        opacity: 1; // Remove the opacity when the button is enabled
        filter: none; // Remove the filter when the button is enabled
    }
    &:hover{
        color:rgb(238, 175, 26) !important;
    }
}

.staff-list-button {
    padding: 5px 18px;
    font-size: 14px;
    border-radius: 5px;
    margin-right: 5px;
    font-weight: normal;
    opacity: 0.35;
    background-color: #033671 !important;
    color: white !important;
    cursor: pointer;
    width: auto !important;
    height: fit-content;

    &:enabled {
        opacity: 1; // Remove the opacity when the button is enabled
        filter: none; // Remove the filter when the button is enabled
    }
}
.inner-table thead th{
    position: -webkit-sticky;
    top: 0;
    z-index: 0;
}
.inner-table tr:nth-child(odd) td
{
  background-color: rgb(243, 243, 243);
  color: black;
}
.inner-table tr:nth-child(even) td
{
  background-color: rgb(249, 249, 249);
  color: black;
}
.inner-table tr.selected td {
    background-color: rgb(185, 184, 184) !important;
}
.inner-table tr:hover td {
    background-color: #ccc;
}
