.search-label{
    font-size: 16px;
    color: #222222;
    margin-right: 3px;
}

.search-input{
    width: 65%;
    height: 25px;
    padding: 2px;
    font-size: .85em;
    border-radius: 5px;
    border: 1px solid #0c0d0f;
    background-color: #F5F5E4 !important;
}

.label-input-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.search-table{
    border-collapse: collapse;
    border: 1px solid #0c0d0f;
    border-radius: 0px;
    margin-top: 10px;
}

.search-table-container{
    max-height: 300px;
    overflow-y: auto;
}

.search-table tr th {
    padding: 2px;
    border-radius: 0px;
}

.search-table tbody tr td{
    padding: 0px;
    border-radius: 0;
}

.search-table th:last-child{
    border-radius: 0;
}

.search-table th:first-child{
    border-radius: 0;
}

.search-selected:hover{
    background-color: #CACACA;
}
