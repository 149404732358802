.autoCommplete{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
}

.inputBox{
    border-radius: 6px;
    border: 1px solid gray;
    font-size: 16px;
    padding: 0px;
    width: 100%;
    max-width: 100%;
}
.inputBox:focus{
    outline: 0;
    border: 1px solid rgb(8 ,8, 8);
}
.autocomplete-dropdown{
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    border-top: 0;
    overflow: auto;
    z-index: 1;
    position: absolute;
    padding: 5px;
    margin-top: 5px;
    border-radius: 4px;
    width: 100%;
}
.autocomplete-dropdown > div{
    cursor: pointer;
    text-align: left;
    padding: 5px;
    color:rgb(43, 43, 43);
    border-top: 1px solid gray;
}
.autocomplete-dropdown > div:first-child{
    border-top: 0;
}
#txt-practice, #txt-location, #txt-city, #txt-zip{
    height: 37px;
    margin-left: 5px;
}

.autocomplete-dropdown td{
    padding: 4px;
    cursor: pointer;
}
.autocomplete-dropdown td:hover{
    background-color: #d4d4d4;
}

.auto-commplete{
    width: 500px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
}

.auto-commplete input{
    width: 100%;
    border: none;
    margin: 10px 0;
    font-size: 16px;
    padding: 10px 10px 0 10px;
}

input:focus{
    outline: none;
}
.suggestions{
    list-style: none;
    margin: 0;
    padding: 0;
    border-top:  1px solid #d4d4d4;
    background-color: #f7f7f7;
    border-radius: 0 0 10px 10px;
}

.suggestions li{
    list-style: none;
    padding: 10px;
    cursor: pointer;
}
.auto-commplete li:hover{
    background-color: #d4d4d4;
}
@media screen and (max-width: 600px) {
    .inputBox {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .inputBox {
        width: 100%;
    }
}

.dropdown-item {
    cursor: pointer;
    // Background when highlighted by arrow keys
    &.highlighted {
        background-color: #c4c4c4; /* Arrow key navigation background color */
    }
    // Background when hovered over
    &:hover {
        background-color: #c4c4c4 !important; /* Hover background color */
    }
    // Remove the arrow key highlight when hovered
    &:hover.highlighted {
        background-color: #f4f4f4; /* Same as hover background color */
    }
}

