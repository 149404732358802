.main-physician-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.physician-profile-header-wrapper {
    color: #222222;
    font-family: Verdana, Arial, sans-serif;
    display: flex;
    width: 100%;
}

.physician-profile-homeaddress-wrapper {
    color: #222222;
    font-family: Verdana, Arial, sans-serif;
    display: block;
    padding: 10px;
    border: 1px solid rgb(18, 56, 92);
    height: 30%;
    width: 100%;
    box-shadow: -5px 0 5px #888;
}

.homeaddress-header {
    color: #000000;
    size: 15px;
    font-size: medium;
    font-weight: 600;
}

.streetaddress .physician-label {
    width: 90px;
    margin-right: 5px;
}

.homeaddress {
    display: inline-flex;
    text-align: center;
}

.cityaddress .physician-label {
    width: 90px;
    margin-right: 5px;
}

.cityaddress .physician-content-tab-input {
    width: 300px;
}

.stateaddress .physician-label {
    width: 50px;
    margin-right: 5px;
}

.stateaddress .physician-content-tab-input {
    width: 90px;
}

.zipaddress .physician-label {
    width: 50px;
    margin-right: 5px;
}

.zipaddress .physician-content-tab-input {
    width: 210px;
}

.physian-profile-info-wrapper {
    color: #222222;
    font-family: Verdana, Arial, sans-serif;
    display: flex;
    width: 100%;
}

.physician-profile-participation-wrapper {
    padding: 10px;
    border: 1px solid rgb(18, 56, 92);
    height: 167px;
    width: 544px;
    display: inline-block;
    box-shadow: -5px 0 5px #888;
}

.physician-profile-practicing-wrapper {
    padding: 5px;
    border: 1px solid rgb(18, 56, 92);
    width: 65%;
    display: inline-block;
    box-shadow: -5px 0 5px #888;
}

.practicing-status .physician-row {
    display: flex;
    justify-content: space-around;
}

.practicing-status .beforeSelect {
    display: flex;
}

.practicing-status .physician-label .beforeSelect {
    flex: 1;
}

.practicing-status .physician-label .afterSelect {
    width: 150px !important;
    flex: 1;
}

.since-calender {
    display: flex;
}

.since-calender .dob-field .form-input {
    width: 180px !important ;
}

.since-calender .physician-calender-side-by-side {
    display: flex;
    gap: 10px;
    flex: 2;
    width: 150px;
}

.since-calender .physician-calender-side-by-side .physician-input .not-allowed {
    width: 100px !important;
}


.physician-profile-medicalGroup-wrapper {
    padding: 10px;
    border: 1px solid rgb(18, 56, 92);
    width: 290px;
    height: 167px;
    display: inline-block;
    box-shadow: -5px 0 5px #888;
}

.physician-profile-appointment-wrapper {
    padding: 5px;
    border: 1px solid rgb(18, 56, 92);
    width: 35%;
    box-shadow: -5px 0 5px #888;
}

.appointmentdiv {
    display: inline-flex;
}

.sentaradiv {
    display: inline-flex;
}

.physician-profile-appointment-wrapper .physician-label {
    width: 135px;
}

.physician-profile-contentwrapper {
    padding: 10px;
    border: 1px solid rgb(18, 56, 92);
    border-radius: 5px;
    color: #222222;
    font-family: Verdana, Arial, sans-serif;
    height: 30%;
    width: 100%;
    box-shadow: -5px 0 5px #888;
}

.participation-toggleWrapper {
    margin-bottom: 8px;
}

.physician-label {
    font-size: 13px !important;
    color: #222222 !important;
    width: 190px;
    text-align: end;
}

.physician-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.physician-input {
    border-radius: 5px;
    background-color: rgb(245, 245, 228) !important;
    width: 230px;
    font-size: 13px;
    color: #222222;
}

.physician-content-tab-input {
    border-radius: 5px;
    background-color: rgb(245, 245, 228) !important;
    width: 450px;
    font-size: 13px;
    color: #222222;
}

.physician-input-title {
    border-radius: 5px !important;
    background: rgb(245, 245, 228) !important;
    width: 40px;
    padding-left: 8px;
    color: #222222 !important;
    height: 24px;
    font: caption !important;
    font-display: block !important;
}


.physician-dropdown {
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    background-color: #E8F0FE !important;
    border-radius: 4px;
    width: max-content;
    height: 28px;
    font-size: 13px;
    color: #000000 !important;
}

.physician-medical-label {
    font-size: 13px !important;
    color: #222222 !important;
    width: 50px;
    text-align: end;
}

.physician-medical-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.physician-medical-input {
    border-radius: 5px;
    background-color: rgb(245, 245, 228) !important;
    width: 160px;
    font-size: 13px;
    color: #222222;
}

.physician-medical-content-tab-input {
    border-radius: 5px;
    background-color: rgb(245, 245, 228) !important;
    width: 450px;
    font-size: 13px;
    color: #222222;
}

.physician-medical-input-title {
    border-radius: 5px !important;
    background: rgb(245, 245, 228) !important;
    width: 40px;
    padding-left: 8px;
    color: #222222 !important;
    height: 24px;
    font: caption !important;
    font-display: block !important;
}


.physician-medical-dropdown {
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    background-color: #E8F0FE !important;
    border-radius: 4px;
    width: max-content;
    height: 28px;
    font-size: 13px;
    color: #000000 !important;
}

.form-check-input {
    margin-bottom: 0 !important;
    padding: 0 !important;
    min-height: 20px !important;
    min-width: 20px !important;
}

.practicingStatus-tab-input {
    border-radius: 5px;
    background-color: rgb(245, 245, 228) !important;
    width: 10vh;
    font-size: 13px !important;
    color: #222222;
}

.content-header {
    color: #000000;
    size: 15px;
    font-size: medium;
    font-weight: 600;
}

.solid-line {
    border-top: 2px solid black;
    width: 100%;
    margin: 5px;
    padding-bottom: 3px;
}

.dob-field .form-input {
    background-color: rgb(245, 245, 228) !important;
    width: 230px;
    border-width: 2px !important;
    height: 24px;
    border-radius: 5px;
    border-color: #696363;
    border: 0.063rem solid #696363;
}

.dob-field .react-calendar__month-view__weekdays__weekday {
    font-size: 10px !important;
}

.calendar-icon {
    position: absolute;
    top: -2.5px !important;
    left: 8px !important;
}

.not-allowed {
    pointer-events: none;

}

.dissable-contents {
  pointer-events: none;
}