.staff-edit-body {
    width: 870px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    border: 2px solid #f1f1f1;
    border-radius: 5px;
    color: #222222;
    font-family: Verdana, Arial, sans-serif;
}

#staff-edit-top-tab {
    display: flex;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #011127;
    background: #042454 url('../../../../public/images/ui-bg_highlight-hard_75_042454_1x100.png') 50% 50% repeat-x;
    color: #fff !important;
    font-weight: bold;
    width: auto;
    height: 40px;
    position: sticky;
    top: 0; 
    z-index: 1;
}

#staff-edit-top-tab .TabsItem,
#staff-edit-top-tab button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #042454 url('../../../../public/images/ui-bg_flat_90_042454_40x100.png') 50% 50% repeat-x;
    color: #fff !important;
    border: 1px solid #011127 !important;
    font-weight: normal !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 6px;
}

#staff-edit-top-tab .nav-link.active {
    background-color: #042454 !important;
    color: #FEC336 !important;
    border: 1px solid #011127;
    border-radius: 5px;
}
.main-tab-wrapper{
    max-height: fit-content;
    overflow: auto;
    max-width: fit-content;
}

.staff-edit-top-tab .TabsItem,
.staff-edit-top-tab button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #042454 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkEAIAAACY3hF0AAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAjklEQVQoz2MozWgXXjiFiYGBgYGBYZTCQf13+h/NUM/0p++P/N8fTD9Df+n9LmX6cfPn419FTN+lf9j++sisNE+fzfERs9xZ7Uu2s5i+Lvj2+ec7pm8Lvn/6+Y6Zb7asgPEBZq7Tknf0K5jZv4p91t3E9GPaz87fn5l+hv7S+1PK9I/zX9a/34PDt0OEAgAMPTxqCmPvSwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNi0wNVQxMDo0Nzo1Ni0wNzowMAswTf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDYtMDVUMTA6NDc6NTYtMDc6MDB6bfVBAAAAAElFTkSuQmCC) 50% 50% repeat-x;
    color: white !important;
    font-weight: normal !important;
    font-size: 15px;
    border: 1px solid black;
}

.staff-edit-warning{
    font-style: italic;
    color: red;
    margin-right: 10vh;
}

.staff-prac-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 96vh;
}