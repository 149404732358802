#main-wrapper{
   display: flex;
   flex-direction: column;
   border: 1px solid #0c0c0f;
   border-radius: 10px;
   max-height: 400px;
   overflow-y: auto;
   padding:10px;
}
.horizontal-line{
    color: #fff;
    background-color: #fff;
    border-top: 1px solid #000;
    width: 100%;
    margin-top: 20px;
}

.profile-tabs li{
    display: flex;
    justify-content: space-around;
    flex:0 !important;
}
.error{
    color:red;
}

#location-top-tab {
    display: flex;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #011127;
    background: #042454 url('../../../public/images/ui-bg_highlight-hard_75_042454_1x100.png') 50% 50% repeat-x;
    color: #fff;
    font-weight: bold;
    width: auto;
    height: 38px;
    margin-bottom: 5px;
}
#location-top-tab .TabsItem,
#location-top-tab button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #042454 url('../../../public/images/ui-bg_flat_90_042454_40x100.png') 50% 50% repeat-x;
    color: #fff !important;
    border: 1px solid #011127 !important;
    font-weight: normal !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 6px;
}

#location-top-tab
.nav-link.active {
    background-color: #042454 !important;
    color: #FEC336 !important;
    border: 1px solid #011127;
    border-radius: 5px;
}