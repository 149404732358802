.staff-language-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    background: #042454 url('../../../../../public/images/ui-bg_flat_90_042454_40x100.png') 50% 50% repeat-x;

    font-weight: normal !important;
    border-radius: 5px;
    font-size: 15px;
}

.staff-language-button.open {
    color: #fec336 !important;
}

.languageInfo-table tr th {
    padding: 5px;
    background-color: rgb(30, 50, 100) !important;
}

.languageInfo-table > tbody{
   font-size: 11px !important;
}

.language-popup {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}