.btn-popup-footer {
    background: linear-gradient(180deg, rgba(0, 36, 83, 1) 0%, rgba(14, 48, 94, 1) 47%, rgba(154, 169, 188, 1) 100%) repeat-x #c0cad7 0 0 / cover;
    color: white !important;
    margin-right: 1%;
    cursor: pointer;
    width: auto !important;
}

.btn-popup-footer:hover {
    color: rgb(204, 171, 94) !important;
}

.button-popup-container {
    text-align: right;
}

.divider {
    border: none;
    border-top: 1px solid #000000;
}

.popup-header {
    color: #ffffff;
    background: linear-gradient(180deg, rgba(0, 36, 83, 1) 0%, rgba(14, 48, 94, 1) 47%, rgba(154, 169, 188, 1) 100%) repeat-x #c0cad7 0 0 / cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.label-checkbox{
    display: flex;
}
