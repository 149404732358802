

 .modal-title{
    border-radius: 2px;
    padding: 3px 3px 7px 15px;
    border: 1px solid #011127;
    background: #042454 url('../../../../public/images/ui-bg_highlight-hard_75_042454_1x100.png') 50% 40% repeat-x;
    color: #fff;
    font-weight: bold;
    font-family: Verdana, Arial, sans-serif;
    font-size: 15px;

}
.loc-modal-w-large  {
    min-width: max-content !important;
    position: revert !important;
    max-height: fit-content !important;
}
.location-list-wrapper {
    width: auto;
    min-height: 22px;
    height: auto;
    border: 0;
    padding: .5em 1em;
    background: none;
    max-height: 500px;
    overflow-y: auto;
}

.loc-list-table {
    width: 100%;
    background-color: white;
    border-collapse: collapse;
    font-size: 11px;
}
 .loc-list-table tbody{
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
 }
 .loc-list-table thead{
    border-bottom: 1px solid black;
    vertical-align: bottom;
 }
 .loc-list-table th{
    text-align: left;
    height: 40px;
    font-weight: normal;
    padding-left: 4px;

 }
 .loc-list-row{
    background-color: rgb(243, 243, 243);
 }

 .selected {
   background-color: #888;
}











