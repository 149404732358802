#container {
    font-family: Verdana,Arial,sans-serif;

}

#title {
    background: #042454 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkEAIAAACY3hF0AAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAjklEQVQoz2MozWgXXjiFiYGBgYGBYZTCQf13+h/NUM/0p++P/N8fTD9Df+n9LmX6cfPn419FTN+lf9j++sisNE+fzfERs9xZ7Uu2s5i+Lvj2+ec7pm8Lvn/6+Y6Zb7asgPEBZq7Tknf0K5jZv4p91t3E9GPaz87fn5l+hv7S+1PK9I/zX9a/34PDt0OEAgAMPTxqCmPvSwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMy0wNi0wNVQxMDo0Nzo1Ni0wNzowMAswTf0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTMtMDYtMDVUMTA6NDc6NTYtMDc6MDB6bfVBAAAAAElFTkSuQmCC) 50% 50% repeat-x;
    color:white;
    padding: 6px !important;
    border-radius: 0px;
    font-size: medium !important;
    font-weight: bold;
}

.button {
    background-color: #033671 !important;
    color: white !important;
    margin-right: 1%;
    cursor: pointer;
    width: auto !important;
    font-size: medium;
    padding: .1em 1em !important;
    border-radius: 5px !important;
}

.button:hover {
    color: #f5b00c !important;
}

.button-disabled {
  background-color: rgba(154, 169, 188, 1) !important;
  color: white !important;
  margin-right: 1%;
  cursor: pointer;
  width: auto !important;
  font-size: medium;
  padding: .1em 1em !important;
  border-radius: 5px !important;
}

#table {
    padding:15px 10px 0px 10px;
    overflow: auto;
}

.table-supergroup {
    box-shadow: 3px 3px 3px #888;
    border: 1px solid black;
}


.table-supergroup > tbody > tr > td {
    cursor: pointer;
    font-size: 11px;
    text-align: center;
}

.table-supergroup > thead > tr > th {
   font-size: small;
   font-family: Verdana,Arial,sans-serif !important;
   text-align: center;
}

.selected-row {
    background-color: rgb(185, 184, 184);
}

.supergroup-baseline {
    border: 1px solid black;
}

.footer-button {
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
}