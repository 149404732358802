.privilage-table-content{
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    overflow: auto !important;
}
.privilage-table {
    margin: 2px auto !important;
    width: 100% !important;
    max-height: 100px !important;
    overflow: auto !important;
    thead tr th{
        top: 0;
        position: sticky;
        z-index: 1;
        background-color: rgb(30, 50, 100) !important;
        color: #ffffff !important;
        font-weight: bold !important;
        font-size: 13px !important;
        border-bottom: none !important;
    }
    tbody tr td {
        background-color: white !important;
        color: black !important;
        font-weight: lighter !important;
        font-size: 12px !important;
        border-bottom: none !important;
    }
}

