.toggle-button-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggle-label {
  font-size: 10px;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 0 10px;
  text-transform: uppercase;
}

.toggle-label.no.active {
  color: #fec336;
  background-color: #042454;
}

.toggle-label.yes.active {
  color: #fec336;
  background-color: #042454;
}

.toggle-button {
  width: 40px;
  height: 10px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #D7D7D7;
}

.toggle-thumb {
  width: 10px;
  height: 10px;
  background-color: #042454;
  border-radius: 50%;
  position: absolute;
  left: 1px;
  transition: left 0.3s ease;
}

.toggle-button.active .toggle-thumb {
  left: 30px;
}

.toggle-button-container :active{
  background-color: #fff;
}
