.practice-main-wrapper{
    display: flex;
    flex-direction: column;
    border: 1px solid #0c0c0f;
    border-radius: 10px;
    max-height: 538px;
    overflow-y: auto;
    padding:10px;
}

#practice-tab-button{
    display: flex;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid #011127;
    background: #042454 url('../../../../public/images/ui-bg_highlight-hard_75_042454_1x100.png') 50% 50% repeat-x;
    color: #fff;
    font-weight: bold;
    width: auto;
    height: 38px;
    margin-bottom: 15px;
}
#practice-tab-button .TabsItem,
#practice-tab-button button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #042454 url('../../../../public/images/ui-bg_flat_90_042454_40x100.png') 50% 50% repeat-x;
    color: #fff !important;
    border: 1px solid #011127 !important;
    font-weight: normal !important;
    font-size: 15px;
    border-radius: 5px;
    padding: 6px;
}

#practice-tab-button 
.nav-link.active{
    background-color: #042454 !important;
    color: #FEC336 !important;
    border: 1px solid #011127;
    border-radius: 5px;
}
