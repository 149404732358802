.manin-clinician-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.clinician-info-wrapper {
    padding: 10px 10px 10px 0px;
    border: 1px solid rgb(18, 56, 92);
    border-radius: 5px;
    color: #222222;
    font-family: Verdana, Arial, sans-serif;
    height: max-content;
    width: 69%;
    box-shadow: -5px 5px 5px #888;
}

.clinician-label-fax,
.clinician-label-gender {
    font-size: 13px !important;
    color: #222222 !important;
    text-align: end;
}

.clinician-label-pcp {
    font-size: 13px !important;
    color: #222222 !important;
    text-align: end;
    margin-left: -8px !important;
}

.clinician-label {
    font-size: 13px !important;
    color: #222222 !important;
    width: 75px;
    text-align: end;
}

.clinician-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    margin-left: 3vh;
}

.clinician-input {
    border-radius: 5px;
    background-color: rgb(245, 245, 228) !important;
    width: 130px;
    font-size: 13px;
    color: #222222;
}

.clinician-input-title {
    border-radius: 5px !important;
    background: rgb(245, 245, 228) !important;
    width: 20vh;
    padding-left: 8px;
    color: #696363 !important;
    font-size: 13px !important;
}

.clinician-input-dob {
    border-radius: 5px !important;
    background: rgb(245, 245, 228) !important;
    width: 12vh;
    padding-left: 8px;
    color: #696363 !important;
    height: 24px;
    font: caption !important;
    font-display: block !important;
    font-size: 13px !important;
}

.clinician-input-email {
    border-radius: 5px;
    background-color: #E8F0FE !important;
    width: 273px;
    font-size: 13px;
    color: #222222;
    padding-left: 8px;
}

.staff-phone-fax-wrapper {
    display: flex;
    gap: 10px;
}

.clinician-dropdown {
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    background-color: #E8F0FE !important;
    border-radius: 4px;
    width: max-content;
    height: 28px;
    font-size: 13px;
    color: #000000 !important;
}
.dotted-line {
    border: 1px dotted #222222;
    border-style: none none dotted;
    margin: 5px;
    opacity: 0.75;
    width: 100%;
}
.form-check-input {
    margin-bottom: 0 !important;
    padding: 0 !important;
    min-height: 20px !important;
    min-width: 20px !important;
}
.clinician-input-image {
    border-radius: 5px;
    width: 200px;
    font-size: 13px;
    color: #222222;
    padding-left: 8px;
    background-color: transparent !important;
}
.image-upload-wrapper {
    width: 230px;
    height: 230px;
    box-shadow: rgb(136, 136, 136) -10px 10px 5px;
    background: url(../../../../public/images/frame4.png) no-repeat;
    position: relative; 
}

.uploaded-image-preview .clinical-selected-image {
    display: inline;
    width: 100% !important;
    height: auto;
    position: absolute;
    top: 0; /* Position the image at the top of the wrapper */
    z-index: 1; /* Ensure the image stays below the image-upload */
}
.image-upload {
    background: rgba(255, 255, 255, 0.5);
    width: 200px;
    padding: 5px;
    margin: 5px;
    text-align: center;
    opacity: 0; /* Make it visible by default */
    position: absolute; /* Position it absolutely within the wrapper */
    z-index: 10; /* Ensure it stays above the image */
    transition: opacity 0.5s ease;
}
.image-upload:hover {
    opacity: 1;
}
.uploaded-image-preview {
    position: relative;
}
.clinical-image-label {
    font-size: 12px;
}
.clinical-image-button-wrapper {
    border: 1px solid #01132d;
    background: #042454 url('../../../../public/images/ui-bg_highlight-hard_80_042454_1x100.png') 50% 50% repeat-x;
    font-weight: normal;
    color: #fff;
    opacity: .35;
    padding: 8px;
    margin: 5px;
    border-radius: 9px;
}

.clinical-image-button-wrapper-enabled{
    border: 1px solid #01132d;
    background: #042454 url('../../../../public/images/ui-bg_highlight-hard_80_042454_1x100.png') 50% 50% repeat-x;
    font-weight: normal;
    color: #fff;
    padding: 8px;
    margin: 5px;
    border-radius: 9px;
}

.uploaded-successfully{
    font-size: 10px;
    color:rgb(0, 128, 0);
}

.upload-required{
    font-size: 10px;
    color:rgb(255, 0, 0);
}
.clinical-image-upload-button {
    width: 16px;
    height: 16px;
    display: block;
    background-image: url('../../../../public/images/ui-icons_fff_256x240.png');
    background-position: -96px -192px;

}
.clinical-image-restore-button {
    width: 16px;
    height: 16px;
    display: block;
    background-image: url('../../../../public/images/ui-icons_fff_256x240.png');
    background-position: -64px -80px;
}
.clinical-image-delete-button {
    width: 16px;
    height: 16px;
    display: block;
    background-image: url('../../../../public/images/ui-icons_fff_256x240.png');
    background-position: -176px -96px;
}
.clinical-success-upload-msg {
    color: rgb(0, 128, 0);
    display: inline;
}